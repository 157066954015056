import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./ProfilePage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const ProfilePage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto", minHeight: "60vh" }}>
        <Section>
          <SectionHeading>Peter King | AI Product Reviews</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <div className="profile-page">
              <div className="profile-page-photo">
                <img src="/images/peter-king.png" />
              </div>
              <div className="profile-page-bio">
                <p>
                  Pete is a 30 year old entrepreneur with a passion and natural
                  affinity for online businesses. He started his first business
                  venture at the age of 13 buying and selling drums. Since then,
                  Pete has gone on to start a number of successful online
                  businesses from the age of 18 to the present day. With this
                  wealth of online experience under his belt, Pete has had
                  multiple successful exits over the last 5 years.&nbsp;
                </p>
                <p>
                  Pete&rsquo;s interests now lie within the world of AI. He is
                  keen to see how businesses&rsquo; operational procedures
                  change with the help of AI and how AI software evolves over
                  the next 10 years. Pete believes it is an exciting time in the
                  digital space and that sharp business minds should embrace the
                  change and leverage AI to streamline business processes and
                  make their lives easier.
                </p>
              </div>
            </div>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default ProfilePage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Peter King | AI Product Reviews"}
      description={
        "Discover Peter King's AI product review expertise and unique insights in the world of artificial intelligence."
      }
      pathname={location.pathname}
    />
  );
};
